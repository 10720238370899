import { Link, useNavigate } from "react-router-dom";
import "./css/Header.scss";
import { FaFacebookF, FaInstagram, FaTwitter, FaBars } from "react-icons/fa";
import { useState, useContext, useEffect } from "react";
import ScrollLock from "./ScrollLock";
import { Link as Navigator } from "react-scroll";
import { ProductContext } from "./Context/ProductsContext";
import About from "../pages/About";
import axios from "axios";


function Header({ aboutRef }) {



  let url = "https://avon.admin.simboz.website/api/products/get-products";

  useEffect(() => {
    axios
      .get(url)
      .then(function (response) {
        setAllproducts(response.data.cats);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  let [active, setActive] = useState("home");
  let [mbtoogle, setMbtoogle] = useState(false);
  const [blockScroll, allowScroll] = ScrollLock();

  let [metalInnerCat, setMetalInnerCat] = useState(false);

  let { allproducts, activetab, setActivetab, subCategory, setSubCategory, setAllproducts, } =
    useContext(ProductContext);

  let hide = () => {
    document.getElementById("abs").style.display = "none";
  };
  let show = () => {
    document.getElementById("abs").style.display = "block";
  };

  let hideabout = () => {
    document.getElementById("abouti").style.display = "none";
  };
  let showabout = () => {
    document.getElementById("abouti").style.display = "block";
  };

  // navigate to about

  // let [ofset, setOfset] = useState(120);
  // window.addEventListener("scroll", (e) => {
  //   window.scrollY > 100 ? setOfset(100) : setOfset(120);
  // });
  // console.log(ofset);
  let navigate = useNavigate();

  const gotoservice = () => {
    navigate("/");
    setActive("service");
    // console.log(window.scrollY > 100 ? "100" : "160");
    setTimeout(() => {
      window.scrollTo({
        behavior: "smooth",
        top:
          document.querySelector("#service").getBoundingClientRect().top -
          document.body.getBoundingClientRect().top -
          (window.scrollY > 100 ? 70 : 140),
      });
    }, 100);
  };

  // navigate to process
  const gotoprocess = () => {
    navigate("/");
    setActive("process");
    setTimeout(() => {
      window.scrollTo({
        behavior: "smooth",
        top:
          document.querySelector("#process").getBoundingClientRect().top -
          document.body.getBoundingClientRect().top -
          (window.scrollY > 100 ? 70 : 140),
      });
    }, 100);
  };

  const gotoBlog = () => {
    setActive("blog");
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }, 100);
  };

  let [servicetog, setServicetog] = useState(false);

  let [aboutsec, setAboutsec] = useState(false);

  window.addEventListener("scroll", (e) => {
    window.scrollY > 100
      ? document.getElementById("webnav").classList.add("sticky")
      : document.getElementById("webnav").classList.remove("sticky");
  });

  const gotohome = () => {
    navigate("/");
    setActive("home");
    setSubCategory("hide");
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }, 100);
  };

  const gotobusiness = () => {
    navigate("/");
    setSubCategory("hide");
    setActive("business");
    setTimeout(() => {
      window.scrollTo({
        behavior: "smooth",
        top:
          document.querySelector("#businesssecment").getBoundingClientRect()
            .top -
          document.body.getBoundingClientRect().top -
          (window.scrollY > 100 ? 70 : 140),
      });
    }, 100);
  };
  const gotoabout = () => {
    navigate("/");
    setSubCategory("hide");
    setActive("about");
    setTimeout(() => {
      window.scrollTo({
        behavior: "smooth",
        top:
          document.querySelector("#about").getBoundingClientRect().top -
          document.body.getBoundingClientRect().top -
          (window.scrollY > 100 ? 70 : 140),
      });
    }, 100);
  };

  const gotoceo = () => {
    setActive("about");
    setSubCategory("hide");
    setTimeout(() => {
      window.scrollTo({
        behavior: "smooth",
        top:
          document.querySelector("#ceo").getBoundingClientRect().top -
          document.body.getBoundingClientRect().top -
          (window.scrollY > 100 ? 70 : 140),
      });
    }, 100);
  };

  const gotoFounder = () => {
    setActive("about");
    setSubCategory("hide");
    setTimeout(() => {
      window.scrollTo({
        behavior: "smooth",
        top:
          document.querySelector("#founder").getBoundingClientRect().top -
          document.body.getBoundingClientRect().top -
          (window.scrollY > 100 ? 70 : 140),
      });
    }, 100);
  };

  const gototeam = () => {
    setActive("about");
    setSubCategory("hide");
    setTimeout(() => {
      window.scrollTo({
        behavior: "smooth",
        top:
          document.querySelector("#team").getBoundingClientRect().top -
          document.body.getBoundingClientRect().top -
          (window.scrollY > 100 ? 70 : 140),
      });
    }, 100);
  };

  let mobileToggle = (param) => {
    console.log(param, "param");
    if (aboutsec == param) {
      setAboutsec("");
    } else {
      setAboutsec(param);
      setActive(param);
    }
  };

  return (
    <div className="navcont " id="webnav">
      {window.screen.width > 1000 ? (
        <div className="innernav">
          <Link
            to={"/"}
            onClick={() => {
              gotohome();
            }}
          >
            <img
              src={"/assets/logo/avon.svg"}
              alt="Avon Commercial Corporation"
            />
          </Link>
          <div className="navright">
            <ul>
              <li
                onClick={() => {
                  gotohome();
                }}
              >
                <Link to="/" className={active == "home" ? "active" : null}>
                  HOME
                </Link>
              </li>
              <li
                onMouseEnter={() => {
                  showabout();
                }}
                onMouseLeave={() => {
                  hideabout();
                }}
              >
                <Link
                  to="/"
                  className={active == "about" ? "active" : null}
                  onClick={() => {
                    return gotoabout(), hideabout();
                  }}
                >
                  {" "}
                  ABOUT US
                </Link>

                <ul className="abouts" id="abouti">
                  <Link
                    onClick={() => {
                      return setActive("about"), hide(), gotoFounder();
                    }}
                    to="/about"
                  >
                    <li>FOUNDER's MESSAGE</li>
                  </Link>

                  <Link
                    onClick={() => {
                      return setActive("about"), hide(), gotoceo();
                    }}
                    to="/about"
                  >
                    <li>CEO's MESSAGE</li>
                  </Link>

                  <Link
                    onClick={() => {
                      return setActive("about"), hide(), gototeam();
                    }}
                    to="/about"
                  >
                    <li>OUR TEAM</li>
                  </Link>
                </ul>
              </li>

              <li
                // onClick={() => { return (setActive('business')) }}
                onMouseEnter={() => {
                  show();
                }}
                onMouseLeave={() => {
                  hide();
                }}
                onClick={() => {
                  hide();
                }}
              >
                <Link
                  to="/"
                  onClick={() => {
                    return gotobusiness(), setSubCategory("hide");
                  }}
                  className={active == "business" ? "active" : null}
                >
                  BUSINESS SEGMENTS
                </Link>

                <ul className="abs" id="abs">
                  {allproducts?.map((item, index) => {
                    return (
                      <Link
                        key={index}
                        onClick={(e) => {
                          return (
                            e.stopPropagation(),
                            setActivetab(index),
                            setActive("business"),
                            hide(),
                            setSubCategory("hide")
                          );
                        }}
                        to={`/business-segment/${item.slug}`}
                      >
                        <li>{item.category}</li>

                        {item.subcategories.length > 0 && (
                          <ul className="subCategories">
                            {item.subcategories.map((subItem, subIndex) => {
                              return (
                                <Link
                                  key={subIndex}
                                  onClick={(e) => {
                                    return (
                                      e.stopPropagation(),
                                      setActivetab(index),
                                      setActive("business"),
                                      hide(),
                                      setSubCategory(subIndex)
                                    );
                                  }}
                                  to={`/business-segment/${item.slug}/${subItem.slug}`}
                                >
                                  <li>{subItem.category}</li>

                                </Link>
                              );
                            })}
                          </ul>
                        )
                        }
                      </Link>
                    );
                  })}
                </ul>
              </li>
              <li
                onClick={() => {
                  return gotoservice();
                }}
              >
                <Link to="/" className={active == "service" ? "active" : null}>
                  SERVICES
                </Link>
              </li>

              <li
                onClick={() => {
                  return gotoprocess();
                }}
              >
                <Link to="/" className={active == "process" ? "active" : null}>
                  PROCESS{" "}
                </Link>
              </li>
              <li
                onClick={() => {
                  return gotoBlog();
                }}
              >
                <Link
                  to="/blogs"
                  className={active == "blog" ? "active" : null}
                >
                  Blogs
                </Link>
              </li>
              {/* <li onClick={() => { setActive('contact') }}><Link to='/contact' className={active == 'contact' ? 'active' : null}>LETS CONNECT</Link></li> */}
              <button className="navbtn">
                <Link to="/contact" className="contactbtn">
                  LETS CONNECT
                </Link>
              </button>
            </ul>
          </div>
        </div >
      ) : (
        <div className="mobilewrap">
          <div
            className="micon"
            onClick={() => {
              return (
                setMbtoogle(!mbtoogle), mbtoogle ? allowScroll() : blockScroll()
              );
            }}
          >
            <FaBars />
          </div>
          <div className="mlogo">
            <Link
              to={"/"}
              onClick={() => {
                return gotohome(), allowScroll(), setMbtoogle(false);
              }}
            >
              <img
                src={"/assets/logo/avon.svg"}
                alt="Avon Commercial Corporation"
              />
            </Link>
          </div>

          <div className={mbtoogle ? "mmenushow" : "mmenuhide"}>
            <ul>
              <li
                onClick={() => {
                  return (
                    gotohome(),
                    allowScroll(),
                    setMbtoogle(false),
                    setSubCategory("hide")
                  );
                }}
              >
                <Link to="/" className={active == "home" ? "active" : null}>
                  HOME
                </Link>
              </li>

              <li className="mobiledd">
                <Link
                  to="/"
                  className={active == "about" ? "active" : null}
                  onClick={() => {
                    return (
                      setActive("about"),
                      gotoabout(),
                      allowScroll(),
                      setMbtoogle(false),
                      setSubCategory("hide")
                    );
                  }}
                >
                  ABOUT US
                </Link>
                <p
                  onClick={() => {
                    // setAboutsec(aboutsec == "about" ? "" : "about");
                    mobileToggle("about");
                  }}
                >
                  +
                </p>
                <ul className={aboutsec == "about" ? "dblock " : "dhide"}>
                  <Link
                    className={active == "ceo" ? "active" : null}
                    onClick={() => {
                      return (
                        allowScroll(),
                        gotoFounder(),
                        setMbtoogle(false),
                        setActive("about"),
                        setAboutsec(false),
                        setSubCategory("hide")
                      );
                    }}
                    to="/about"
                  >
                    <li>FOUNDER's MESSAGE</li>
                  </Link>

                  <Link
                    className={active == "ceo" ? "active" : null}
                    onClick={() => {
                      return (
                        allowScroll(),
                        gotoceo(),
                        setMbtoogle(false),
                        setActive("about"),
                        setAboutsec(false),
                        setSubCategory("hide")
                      );
                    }}
                    to="/about"
                  >
                    <li>CEO's MESSAGE</li>
                  </Link>

                  <Link
                    to={"/about"}
                    className={active == "ourteam" ? "active" : null}
                    onClick={() => {
                      return (
                        allowScroll(),
                        gototeam(),
                        setMbtoogle(false),
                        setActive("about"),
                        setAboutsec(false),
                        setSubCategory("hide")
                      );
                    }}
                  >
                    <li>OUR TEAM</li>
                  </Link>
                </ul>
              </li>

              <li
                className="mobiledd"
                onClick={() => {
                  mobileToggle("businesssegment");
                  // setAboutsec(
                  //   aboutsec == "businesssegment" ? "" : "businesssegment"
                  // );
                }}
              >
                <a
                  href="#"
                  className={aboutsec == "businesssegment" ? "active" : null}
                >
                  BUSINESS SEGMENTS
                </a>
                <p>+</p>
                <ul
                  className={
                    aboutsec == "businesssegment" ? "dblock " : "dhide"
                  }
                >
                  {console.log(allproducts, "allproducts")}

                  {allproducts?.map((item, index) => {
                    return (
                      <Link
                        key={index}
                        onClick={() => {
                          return (
                            setActivetab(index),
                            allowScroll(),
                            setMbtoogle(false),
                            setActive("businesssegment"),
                            setServicetog(false),
                            setSubCategory("hide")
                          );
                        }}
                        to={`/business-segment/${item.slug}`}
                      >
                        <li
                          className={
                            item.subcategories.length > 0 && "metalWrap"
                          }
                        >
                          {item.category}
                          {item.subcategories.length > 0 && (
                            <>
                              <span
                                className="showMetalcat"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  e.preventDefault();
                                  setMetalInnerCat(!metalInnerCat);
                                }}
                              >
                                +
                              </span>
                              <ul
                                className="subCateMetal"
                                style={{
                                  display: metalInnerCat ? "block" : "none",
                                }}
                              >
                                {item.subcategories.map((subcat, subIndex) => {
                                  return (
                                    <Link
                                      key={subIndex}
                                      onClick={() => {
                                        return (
                                          setActivetab(index),
                                          allowScroll(),
                                          setMbtoogle(false),
                                          setActive("businesssegment"),
                                          setServicetog(false),
                                          setSubCategory(subIndex)
                                        );
                                      }}
                                      to={`/business-segment/${item.slug}/${subcat.slug}`}
                                    >
                                      <li>{subcat.category}</li>
                                    </Link>
                                  );
                                })}
                              </ul>
                            </>
                          )}
                        </li>
                      </Link>
                    );
                  })}
                </ul>
              </li>

              <li
                onClick={() => {
                  return (
                    gotoservice(),
                    allowScroll(),
                    setMbtoogle(false),
                    setSubCategory("hide")
                  );
                }}
                style={{ marginBottom: "15px" }}
              >
                <Link to="#" className={active == "service" ? "active" : null}>
                  SERVICES
                </Link>
              </li>

              <li
                style={{ marginBottom: "15px" }}
                onClick={() => {
                  return (
                    gotoprocess(),
                    allowScroll(),
                    setMbtoogle(false),
                    setSubCategory("hide")
                  );
                }}
              >
                <Link
                  to="/about"
                  className={active == "process" ? "active" : null}
                >
                  PROCESS{" "}
                </Link>
              </li>

              <li
                style={{ marginBottom: "15px" }}
                onClick={() => {
                  return (
                    gotoBlog(),
                    allowScroll(),
                    setMbtoogle(false),
                    setSubCategory("hide")
                  );
                }}
              >
                <Link
                  to="/blogs"
                  className={active == "blog" ? "active" : null}
                >
                  Blogs
                </Link>
              </li>

              <li
                onClick={() => {
                  return (
                    setActive("contact"),
                    allowScroll(),
                    setMbtoogle(false),
                    setSubCategory("hide")
                  );
                }}
              >
                <Link to="/contact" className="contactbtnmob">
                  LETS CONNECT
                </Link>
              </li>
            </ul>
          </div>
        </div>
      )
      }
    </div >
  );
}

export default Header;
