import React, { useEffect } from "react";
import "./Blogdetail.scss";
import { FaEnvelope, FaPhoneAlt } from "react-icons/fa";
import { useState } from "react";
import axios from "axios";
import { Helmet } from "react-helmet";

function Blogdetail() {
  let slug = window.location.href.split("/").pop();

  const api = `https://avon.admin.simboz.website/api/blogs/getBlogBySlug/${slug}`;
  let [data, setData] = useState();
  let getBlog = () => {
    axios
      .get(api)
      .then((res) => {
        console.log(res);
        setData(res.data.blog);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const scrollToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };
  useEffect(() => {
    scrollToTop();
    getBlog();
  }, []);

  function createMarkup(data) {
    return { __html: data };
  }

  function isValidJson(content) {
    try {
      JSON.parse(content);
      return true;
    } catch (error) {
      return false;
    }
  }
  {
    console.log("data", data);
  }
  return (
    <>
      <Helmet>
        <meta
          name="robots"
          content="index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1"
        />
        <title>{data && data.title}</title>
        <meta name="Blog" content="Blog" />
        <meta
          name="description"
          content={data && data.summary}
          data-react-helmet="true"
        />
      </Helmet>

      {data && data != "" ? (
        <div className={"blogdetailpage"}>
          <div className={"blogdetailbanner"}>
            <img src={data && `https://avon.admin.simboz.website/storage/${data.desktop_banner}`} />
          </div>
          <div className={"blogdetailwrapper"}>
            <h1>{data.title}</h1>
            {isValidJson(data.content) && (
              <div
                className={"inner"}
                dangerouslySetInnerHTML={createMarkup(
                  data ? JSON.parse(data.content) : ""
                )}
              ></div>
            )}
            {!isValidJson(data.content) && (
              <div
                className={"inner"}
                dangerouslySetInnerHTML={createMarkup(data ? data.content : "")}
              ></div>
            )}
          </div>
          {/* <div dangerouslySetInnerHTML={createMarkup(data.content)}></div> */}
          {/* <div>data.content = {data.content}</div> */}
        </div>
      ) : (
        <div className={"loader"}>
          <img
            src="/assets/service/loadingicon.gif"
            width={100}
            height={100}
            className={"image"}
          />
        </div>
      )}
    </>
  );
}

export default Blogdetail;
