import React, { useEffect } from "react";
import "./css/Homebusinesssegment.scss";
import { Link } from "react-router-dom";
import { useState, useContext } from "react";
import { ProductContext } from "./Context/ProductsContext";
import axios from "axios";

function Homebusinesssegment() {
  let {
    allproducts,
    setAllproducts,
    activetab,
    setActivetab,
    setSingleproduct,
    catimage,
    setCatimage,
    subCategory,
    setSubCategory,
  } = useContext(ProductContext);

  let sttop = () => {
    window.scrollTo(0, 0);
  };
  let url = "https://avon.admin.simboz.website/api/products/get-products";
  let [loading, setLoading] = useState(false);

  useEffect(() => {
    sttop();

    axios
      .get(url)
      .then(function (response) {
        setAllproducts(response.data.cats);

        let scat = response.data.cats[activetab];

        let b = scat.products.sort((a, b) => {
          return a.sort_by - b.sort_by;
        });

        setLoading(true);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  return (
    <div className="homebusinesswrapper" id="businesssecment">
      <div className="homebusiness">
        <h6 className="h1">BUSINESS SEGMENTS</h6>
        <div className="setmentcats">
          {allproducts?.map((item, index) => {
            return (
              <>
                <Link
                  onClick={() => {
                    return setActivetab(index), setSubCategory("hide");
                  }}
                  to={`/business-segment/${item.slug}`}
                >
                  <img
                    src={`https://avon.admin.simboz.website/storage/${item.image}`}
                    alt={item.category}
                  />
                  <li>{item.category}</li>
                </Link>
              </>
            );
          })}
        </div>
        {/* <div className="setmentcats">
          <Link
            onClick={() => {
              return setActivetab(0), setSubCategory("hide");
            }}
            to="/business-segment/fibers-&-yarns"
          >
            <img src="assets/service/fabric.webp" alt="FIBERS & YARNS" />
            <li>FIBERS & YARNS</li>
          </Link>
          <Link
            onClick={() => {
              return setActivetab(1), setSubCategory("hide");
            }}
            to="/business-segment/textiles"
          >
            <img src="assets/service/textile.webp" alt="FABRICS" />
            <li>FABRICS</li>
          </Link>
          <Link
            onClick={() => {
              return setActivetab(2), setSubCategory("hide");
            }}
            to="/business-segment/petrochemicals"
          >
            <img src="assets/service/petro.webp" alt="PETROCHEMICALS" />
            <li>PETROCHEMICALS</li>
          </Link>
          <Link
            onClick={() => {
              return setActivetab(3), setSubCategory("hide");
            }}
            to="/business-segment/solvents"
          >
            <img src="assets/service/petrochemical.webp" alt="SOLVENTS" />
            <li>SOLVENTS</li>
          </Link>
          <Link
            onClick={() => {
              return setActivetab(4), setSubCategory("hide");
            }}
            to="/business-segment/basic-chemicals"
          >
            <img
              src="assets/service/basicchemicals.webp"
              alt="BASIC CHEMICALS"
            />
            <li>BASIC CHEMICALS</li>
          </Link>
          <Link
            onClick={() => {
              return setActivetab(5), setSubCategory("hide");
            }}
            to="/business-segment/films-&-sheets"
          >
            <img src="assets/service/filmsandsheet.webp" alt="FILMS & SHEETS" />
            <li>FILMS & SHEETS</li>
          </Link>{" "}
          <Link
            onClick={() => {
              return setActivetab(6), setSubCategory("hide");
            }}
            to="/business-segment/metal-scrap"
          >
            <img src="assets/service/matal.webp" alt="METAL SCRAP" />
            <li>METAL SCRAP</li>
          </Link>
        </div> */}
      </div>
    </div>
  );
}

export default Homebusinesssegment;
