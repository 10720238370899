import axios, { all } from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Subbanner from "../components/Subbanner";
import "./Services.scss";
import { useContext } from "react";
import { ProductContext } from "../components/Context/ProductsContext";
import Popupform from "../components/Popupform";
import { Helmet } from "react-helmet";

function Services() {
  let sttop = () => {
    window.scrollTo(0, 0);
  };

  let url = "https://avon.admin.simboz.website/api/products/get-products";
  let {
    allproducts,
    setAllproducts,
    activetab,
    setActivetab,
    setSingleproduct,
    catimage,
    setCatimage,
    subCategory,
    setSubCategory,
  } = useContext(ProductContext);

  let [loading, setLoading] = useState(false);
  // let [sorted, setSorted] = useState([]);

  useEffect(() => {
    sttop();


    axios
      .get(url)
      .then(function (response) {
        setAllproducts(response.data.cats);

        // let scat = response.data.cats[activetab];
        // console.log(scat.products, 'sd')
        // let b = scat.products.sort((a, b) => {
        //   return a.sort_by - b.sort_by;
        // });
        // console.log(b, 'sorted data')

        setLoading(true);
      })
      .catch(function (error) {
        console.log(error);
      });

  }, []);

  let imagearr = [
    "assets/service/fabric.png",
    "assets/service/textile.png",
    "assets/service/petrochemical.png",
    "assets/service/solvents.png",
    "assets/service/basicchemical.png",
    "assets/service/filmsandsheet.png",
  ];

  localStorage.setItem("image", imagearr[activetab]);

  function createMarkup(item) {
    return { __html: item };
  }


  let urlL = window.location.href.split("/").at(-1);

  useEffect(() => {
    if (urlL == "fibers-&-yarns") {
      setActivetab(0);

    } else if (urlL === "textiles") {
      setActivetab(1);
    } else if (urlL === "petrochemicals") {
      setActivetab(2);
    } else if (urlL === "solvents") {
      setActivetab(3);
    } else if (urlL === "basic-chemicals") {
      setActivetab(4);
    } else if (urlL === "films-&-sheets") {
      setActivetab(5);
    } else if (urlL === "ferrous-scrap") {
      setActivetab(6);
      setSubCategory(0);
    } else if (urlL === "non-ferrous-scrap") {
      setActivetab(6);
      setSubCategory(1);
    } else if (urlL === "re-rollable-scrap") {
      setActivetab(6);
      setSubCategory(2);
    } else if (urlL === "isri-specifications") {
      setActivetab(6);
      setSubCategory(3);
    } else {
      setActivetab(6);
    }
  }, [urlL]);

  return (
    <>
      {/* {console.log(activetab, 'activetab', subCategory, "subCategory", allproducts[activetab]?.subcategories[subCategory], "allproducts")}
      {console.log(urlL, "urlL", subCategory, 'subCategory')} */}
      <Helmet>
        <meta
          name="robots"
          content="index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1"
        />

        <title>{subCategory == 'hide' ? allproducts[activetab]?.meta_title : allproducts[activetab]?.subcategories[subCategory].meta_title}</title>




        <link
          rel="canonical"
          href={`https://www.avoncommercial.net/business-segment/${urlL}`}
        />
        <meta
          name="description"
          content={subCategory == 'hide' ? allproducts[activetab]?.meta_description : allproducts[activetab]?.subcategories[subCategory].meta_description}
          data-react-helmet="true"
        />
        {/* open graph */}
        <meta
          property="og:url"
          content={`https://www.avoncommercial.net/business-segment/${urlL}`}
        />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={subCategory == 'hide' ? allproducts[activetab]?.meta_title : allproducts[activetab]?.subcategories[subCategory].meta_title} />
        <meta property="og:description" content={subCategory == 'hide' ? allproducts[activetab]?.meta_description : allproducts[activetab]?.subcategories[subCategory].meta_description} />
        <meta
          property="og:image"
          content="https://www.avoncommercial.net/assets/service/service.webp"
        />
        {/* <!-- Twitter Meta Tags --> */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta property="twitter:domain" content="byeindonesia.com" />
        <meta
          property="twitter:url"
          content={`https://www.avoncommercial.net/business-segment/${urlL}`}
        />
        <meta name="twitter:title" content={subCategory == 'hide' ? allproducts[activetab]?.meta_title : allproducts[activetab]?.subcategories[subCategory].meta_title} />
        <meta name="twitter:description" content={subCategory == 'hide' ? allproducts[activetab]?.meta_description : allproducts[activetab]?.subcategories[subCategory].meta_description} />
        <meta
          name="twitter:image"
          content="https://www.avoncommercial.net/assets/service/service.webp"
        />
        <link
          rel="canonical"
          href={`https://www.avoncommercial.net/business-segment/${urlL}`}
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={subCategory == 'hide' ? allproducts[activetab]?.meta_title : allproducts[activetab]?.subcategories[subCategory].meta_title} />
        <meta property="og:description" content={subCategory == 'hide' ? allproducts[activetab]?.meta_description : allproducts[activetab]?.subcategories[subCategory].meta_description} />
        <meta
          property="og:url"
          content={`https://www.avoncommercial.net/business-segment/${urlL}`}
        />
        <meta property="og:site_name" content="Avon Commercial" />

        {/* open graph */}
      </Helmet>
      <div className="services">
        <Subbanner
          image={"/assets/service/service.webp"}
          title={allproducts[activetab]?.title}
          newTitle={allproducts[activetab]}
          catIndex={subCategory}
        />

        <div className="productwrap">
          {loading ? (
            <div className="divider">
              <div className="catsec">
                {allproducts?.map((item, index) => {
                  return (
                    <div key={index}>
                      <p
                        className={
                          index == activetab ? "activecategory" : "categoryname"
                        }
                        onClick={() => {
                          return setActivetab(index), setSubCategory("hide");
                        }}
                      >
                        {item.category}
                      </p>

                    </div>
                  );
                })}
              </div>
              <div className="productsec">
                {subCategory == "hide" ? (
                  <>
                    {allproducts[activetab]?.products.map((item, index) => {
                      return (
                        <div key={index} className="productbox">
                          <div className="upperwrap">
                            <Link
                              to={`/products/${item.slug}`}
                              onClick={() => {
                                return (
                                  setSingleproduct(item),
                                  setCatimage(imagearr[activetab])
                                );
                              }}
                            >
                              <p className="heading">{item.title}</p>
                            </Link>
                            <p
                              dangerouslySetInnerHTML={createMarkup(
                                item.summary
                              )}
                              className="desc"
                            ></p>
                          </div>
                          <Popupform title={item.title} btnstyle="button" />
                        </div>
                      );
                    })}
                    {allproducts[activetab]?.subcategories.map(
                      (item, index) => {
                        return (
                          <div key={index} className="productbox">
                            <div className="upperwrap">
                              <p className="heading">{item.category}</p>
                              <p className="desc">{item.description}</p>
                            </div>
                            <button
                              className="button"
                              onClick={() => {
                                setSubCategory(index);
                              }}
                            >
                              View Products
                            </button>
                          </div>
                        );
                      }
                    )}
                  </>
                ) : (
                  <>
                    {allproducts[activetab]?.subcategories[
                      subCategory
                    ]?.products.map((item, index) => {
                      return (
                        <div key={index} className="productbox">
                          <div className="upperwrap">
                            <Link
                              to={`/products/${item.slug}`}
                              onClick={() => {
                                return (
                                  setSingleproduct(item),
                                  setCatimage(imagearr[activetab])
                                );
                              }}
                            >
                              <p className="heading">{item.title}</p>
                            </Link>

                            <p
                              dangerouslySetInnerHTML={createMarkup(
                                item.summary
                              )}
                              className="desc"
                            ></p>
                          </div>
                          <Popupform title={item.title} btnstyle="button" />
                        </div>
                      );
                    })}
                  </>
                )}


                {allproducts[activetab] && (
                  <div
                    className="seoBoxContainer"
                    dangerouslySetInnerHTML={createMarkup(subCategory == 'hide' ? allproducts[activetab]?.meta_footer : allproducts[activetab]?.subcategories[subCategory].meta_footer)}
                  ></div>
                )}


              </div>
            </div>
          ) : (
            <img src={"/assets/service/loadingicon.gif"} />
          )}
        </div>
      </div>
    </>
  );
}

export default Services;
