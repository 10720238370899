import React, { useEffect, useState } from "react";
import "./css/Ourteam.scss";
import Slider from "react-slick";
function Ourteam() {
  const data = [
    {
      image: "assets/icons/1.svg",
      head: "Enquiry",
      desc: "The starting point of sales and purchases. Enquiries lead to the relevant vendor/supplier information along with specs sharing and packing details.",
    },
    {
      image: "assets/icons/2.svg",
      head: "Quote",
      desc: "Quotations include pricing, delivery timelines, payment terms, custom clearance charges, VAT, etc. After accepting a quote, the prerequisites are ordered.",
    },
    {
      image: "assets/icons/3.svg",
      head: "Order Confirmation",
      desc: `When your purchase order and our sales order are in agreement with one another, those orders combine to form a legally binding contract between us to work according to your specifications`,
    },
    {
      image: "assets/icons/4.svg",
      head: "Payment",
      desc: `A letter of credit from a bank guaranteeing that a buyer’s payment to a seller will be received on time and for the correct amount. If the buyer is unable to make a payment on the purchase, the bank will be required to cover the full or remaining amount of the purchase.`,
    },
    {
      image: "assets/icons/5.svg",
      head: "Production",
      desc: "A complete production and delivery timeline will be provided to the customer upon receiving the Letter of Credit.",
    },

    {
      image: "assets/icons/6.svg",
      head: "Quality Control",
      desc: "We ensure that the manufactured products adhere to a defined set of quality criteria and meet customer needs.",
    },

    {
      image: "assets/icons/7.svg",
      head: "Shipping & Logistics",
      desc: "We ensure timely shipments and also help our customers in Warehousing, Customs Brokerage, Tariff Calculation and transportation management.",
    },
    {
      image: "assets/icons/aftersales.svg",
      head: "After Sales Service",
      desc: `We provide any assistance that is required by our valuable clients after they've purchased a product. It covers technical assistance, product inspection and claim management.`,
    },
  ];

  let [items, setItems] = useState(4);
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: items,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };
  let team = [
    {
      image: "/assets/team/danish.webp",
      name: "Mr. Danish Shahid",
      des: "Business Unit Head",
    },
    {
      image: "/assets/team/mubashir.webp",
      name: "Mr. Mubashir Ahmed",
      des: "Sr. Sales Manager",
    },

    {
      image: "/assets/team/Bilal.webp",
      name: "Mr. Mirza Bilal Anwar",
      des: "Sr. Sales Manager",
    },
    {
      image: "/assets/team/wahajTwo.webp",
      name: "Mr. Wahaj Taj",
      des: "Sr. Sales Officer",
    },

    {
      image: "/assets/team/raza.webp",
      name: "Mr. Raza Hussain",
      des: "Sales Officer",
    },
    {
      image: "/assets/team/kiranTwo.webp",
      name: "Ms. Kiran Gill",
      des: "Sales Officer",
    },
    {
      image: "/assets/team/Daniyal.webp",
      name: "Mr. Daniyal Butt",
      des: "Sales Officer",
    },
    {
      image: "/assets/team/HudaQureshi.webp",
      name: "Ms. Huda Qureshi",
      des: "Sales Officer",
    },
    {
      image: "/assets/team/MalaikaAli.webp",
      name: "Ms. Malaika Ali",
      des: "Sales Officer",
    },
    {
      image: "/assets/team/ahmed.webp",
      name: "Mr. Ahmed Hussain",
      des: "Sales Officer",
    },{
      image: "/assets/team/MuhammadUmer.webp",
      name: "Muhammad Umer ",
      des: " Cotton Sales",
    },
    {
      image: "/assets/team/MuhammadAli.webp",
      name: "Mr. Muhammad Ali",
      des: " Accounts Officer",
    },
    {
      image: "/assets/team/Uzma.webp",
      name: "Ms. Uzma Zaman",
      des: "Documentation & Compliance Officer",
    },

  ];
  useEffect(() => {
    if (window.screen.width > 1500) {
      setItems(4);
    } else if (window.screen.width > 1010 && window.screen.width < 1500) {
      setItems(3);
    } else if (window.screen.width > 678 && window.screen.width < 1010) {
      setItems(2);
    } else {
      setItems(1);
    }
  }, []);

  return (
    <div className="outteam" id="team">
      <h6 className="hedi">Our Team</h6>

      <div className="inner2">
        <Slider {...settings}>
          {team.map((item, index) => {
            return (
              <div key={index} className="outteamSliderSimfstCol">
                <span className="icon">
                  <img src={item.image} alt={item.name} />
                </span>
                <div className="divi">
                  <p className="headinginner">{item.name}</p>
                  <p className="parainner">{item.des}</p>
                </div>
              </div>
            );
          })}
        </Slider>
      </div>
    </div>
  );
}

export default Ourteam;
