import "./App.css";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Contact from "./pages/Contact";
import About from "./pages/About";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Services from "./pages/Services";
import Product from "./components/Product";
import { ProductContext } from "./components/Context/ProductsContext";
import { useState, useContext } from "react";
import { useRef } from "react";
import Blogdetail from "./pages/Blogdetail";
import Ceo from "./pages/Ceo";
import Test from "./pages/Test";
import PagenotFound from "./pages/404";
import FabricLandPage from "./pages/FabricLandPage";
import Typage from "./pages/Typage";
import Blogs from "./pages/Blogs";
function App() {
  let [allproducts, setAllproducts] = useState([]);

  let [activetab, setActivetab] = useState(0);

  let [subCategory, setSubCategory] = useState("hide");

  let [singleproduct, setSingleproduct] = useState();

  let [catimage, setCatimage] = useState("");

  const aboutRef = useRef();

  return (
    <ProductContext.Provider
      value={{
        allproducts,
        setAllproducts,
        activetab,
        setActivetab,
        singleproduct,
        setSingleproduct,
        catimage,
        setCatimage,
        subCategory,
        setSubCategory,
      }}
    >
      <div className="App">
        <Router>
          <Header aboutRef={aboutRef} />

          <Routes>
            <Route path="/" exact element={<Home aboutRef={aboutRef} />} />
            <Route
              path="/about"
              exact
              element={<About aboutRef={aboutRef} />}
            />

            <Route path="/business-segments" exact element={<Services />} />

            <Route
              path="/business-segment/fibers-&-yarns"
              element={<Services />}
            />
            <Route path="/business-segment/textiles" element={<Services />} />
            <Route
              path="/business-segment/petrochemicals"
              element={<Services />}
            />
            <Route path="/business-segment/solvents" element={<Services />} />
            <Route
              path="/business-segment/basic-chemicals"
              element={<Services />}
            />
            <Route
              path="/business-segment/films-&-sheets"
              element={<Services />}
            />
            <Route
              path="/business-segment/metal-scrap"
              element={<Services />}
            />
            <Route
              path="/business-segment/metal-scrap/ferrous-scrap"
              element={<Services />}
            />
            <Route
              path="/business-segment/metal-scrap/non-ferrous-scrap"
              element={<Services />}
            />
            <Route
              path="/business-segment/metal-scrap/re-rollable-scrap"
              element={<Services />}
            />
            <Route
              path="/business-segment/metal-scrap/isri-specifications"
              element={<Services />}
            />
            <Route path="/ceo-message" element={<Ceo />} />

            <Route path="/products/:name" exact element={<Product />} />

            <Route path="/contact" exact element={<Contact />} />

            <Route path="/form-submit" exact element={<Typage />} />
            <Route path="/test" exact element={<Test />} />

            <Route path="/blog/:slug" element={<Blogdetail />} />
            <Route path="/fabric-landing-page" element={<FabricLandPage />} />
            <Route path="/blogs" element={<Blogs />} />

            <Route path="/*" element={<PagenotFound />} />
          </Routes>

          <Footer />
        </Router>
      </div>
    </ProductContext.Provider>
  );
}

export default App;
